<template>
    <section class="orderconfirmation">
        <h3 class="h3 border-divider mb-4">
            {{ t(isPreOrderMode ? 'preOrderDetails' : 'reservationDetails') }}
        </h3>
        <div v-if="order">
            <b-row class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{
                        t(isPreOrderMode ? 'preordernumber' : 'reservationDetails_OrderNumber')
                    }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ order.orderNumber }}</span></b-col
                >
            </b-row>
            <b-row class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{
                        t(isPreOrderMode ? 'preOrderDetails_Date' : 'reservationDetails_Date')
                    }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ order.orderDate | date }}</span></b-col
                >
            </b-row>
            <b-row v-if="!isConsignmentOrder" class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{ t('expirationDate') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">
                        {{
                            order.expirationDate
                                | date({
                                    isUTC: order.orderHeaderId == order.orderNumber,
                                })
                        }}</span
                    ></b-col
                >
            </b-row>
            <b-row class="mb-5">
                <b-col sm="6" md="4" xl="3">
                    <span>{{ t('customer') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ order.customer }}</span>
                    <span v-if="order.ocosCustomerId" class="text-muted">
                        ({{ order.ocosCustomerId }})</span
                    >
                </b-col>
            </b-row>
            <div class="pb-3">
                <h4 class="h4 mb-2 border-divider font-weight-bold">
                    <template v-if="isConsignmentOrder">
                        {{ t('consignment_reservationDetails_LensInReservation') }}
                    </template>
                    <template v-else>
                        <div v-if="isPreOrderMode">
                            <div
                                class="h4"
                                v-html="t('cancelPreOrderNote', {customerName: order.customer})"
                            ></div>
                            <br />
                        </div>
                        {{
                            t(
                                isPreOrderMode
                                    ? 'reservationDetails_LensInPreOrder'
                                    : 'reservationDetails_LensInReservation'
                            )
                        }}
                    </template>
                </h4>
                <div v-for="lens in lenses" :key="lens.orderItemId">
                    <h5 class="text-center my-3">
                        {{ t('checkout_Patient') }}:
                        <router-link
                            :to="{
                                name: 'PatientDetails',
                                params: {
                                    patientId: lens.patientId,
                                },
                            }"
                            class="text-primary font-weight-bold"
                        >
                            <u>{{ lens.patientOcosId }}</u>
                        </router-link>
                    </h5>
                    <LensCard
                        :key="`card-${lens.orderItemId}`"
                        :lens="lens"
                        :readonly="false"
                        :show-expiration-window="false"
                        :show-add-buttom="statusAllowAction(order) && canAddToCart"
                        :show-remove-buttom="statusAllowAction(order) && canCancel"
                        :add-buttom-key-text="
                            isConsignmentOrder ? 'consume' : 'inventory_AddToCart'
                        "
                        remove-buttom-key-text="cancel"
                        :remove-prompt-key-text="promptKeyText"
                        @on-add="addToCartOrConsume"
                        @on-remove="cancel"
                    >
                        <template slot="badge">
                            <b-badge v-if="order.subStatus" pill :variant="badgeColor">
                                {{ order.subStatus }}
                            </b-badge>
                        </template>
                        <template slot="custom-actions-before">
                            <h4 class="mt-2 font-weight-bold text-capitalize text-center">
                                <b-badge v-if="order.subStatus" pill :variant="badgeColor">
                                    {{ order.subStatus }}
                                </b-badge>
                            </h4>
                            <h5 v-if="order.subStatus">{{ order.userName }}</h5>
                            <h6 v-if="isOrdered" class="text-center">
                                <router-link
                                    :to="{
                                        name: 'OrderConfirmation',
                                        params: {orderId: lens.followUpOrderHeaderId},
                                    }"
                                    class="record-link text-primary mb-1"
                                >
                                    {{ lens.followUpOrderHeaderId }}
                                </router-link>
                            </h6>
                        </template>
                    </LensCard>
                    <LensPrompt
                        :key="`prompt-${lens.orderItemId}`"
                        :lens="lens"
                        :show.sync="showConsumePrompt"
                        :showExpirationWindow="false"
                        removePromptKeyText="reservationDetails_ConsumeReservationPrompt"
                        @on-remove="consume"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import get from 'lodash/get';
import LensCard from '@/components/LensCard.vue';
import LensPrompt from '@/components/LensPrompt.vue';
import {ITEM_TYPES} from '@/store/modules/cart';
import {OrderSubStatus} from '@/constants/order';
import {InventorySource} from '@/constants/inventory';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'ReserveDetails',
    components: {LensCard, LensPrompt},
    props: {
        isPreOrderMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showConsumePrompt: false,
        };
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        ...mapState({
            order: (state) => state.orders.selected,
        }),
        lenses() {
            return get(this.order, 'items', []).filter((item) => item.itemType === ITEM_TYPES.LENS);
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },
        canAddToCart() {
            return this.isPreOrderMode
                ? false
                : this.checkPermissions({
                      [PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE],
                  });
        },
        canCancel() {
            return this.isPreOrderMode
                ? false
                : this.checkPermissions({
                      [PERMISSIONS.RESERVATIONS]: [PERMISSIONS_VALUES.READ_WRITE],
                  });
        },
        badgeColor() {
            switch (this.order.subStatus) {
                case OrderSubStatus.CANCELLED:
                    return 'danger';
                default:
                    return 'primary';
            }
        },
        isOrdered() {
            return this.order.subStatus == OrderSubStatus.ORDERED;
        },
        isConsignmentOrder() {
            const [lens] = this.lenses;
            return lens?.lensSourceId == InventorySource.CONSIGNMENT;
        },
        promptKeyText() {
            return this.order.subStatus === OrderSubStatus.PREORDER_RESERVATION
                ? 'reservationDetails_CancelPreOrderReservationPrompt'
                : 'reservationDetails_CancelReservationPrompt';
        },
    },
    methods: {
        get,
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        statusAllowAction(order) {
            const allowedStatus = [OrderSubStatus.PREORDER_RESERVATION];
            if (!this.isDistributorSurgeonMode) {
                allowedStatus.push(OrderSubStatus.PREORDER);
            }
            return !order.subStatus || allowedStatus.includes(order.subStatus);
        },
        async cancel() {
            const success = await this.updateOrder('cancel');
            if (success) {
                this.order.subStatus = OrderSubStatus.CANCELLED;
            }
        },
        async addToCartOrConsume() {
            if (this.isConsignmentOrder) {
                this.showConsumePrompt = true;
                return;
            }
            const success = await this.updateOrder('addtocart');
            if (success) {
                this.order.subStatus = OrderSubStatus.IN_CART;
                await this.blockingRequest('cart/fetchItemsCount');
            }
        },
        async consume() {
            const success = await this.updateOrder('consume');
            if (success) {
                this.order.subStatus = OrderSubStatus.CONSUMED;
                this.showConsumePrompt = false;
            }
        },
        async updateOrder(status) {
            const {success} = await this.blockingRequest('orders/update', {
                orderHeaderId: this.order.orderHeaderId,
                status,
            });
            return success;
        },
    },
};
</script>
<style lang="scss" scoped>
.border-divider {
    border-bottom: 3px solid #91979d;
    padding-bottom: 30px;
}
</style>
